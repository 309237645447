/**
 * Module dependencies
 */
import React from 'react';

import { bool, func, object } from 'prop-types';
import { injectI18n } from 'nordic/i18n';
import heartEmptyIcon from '../icons/heart-empty';
import heartFullIcon from '../icons/heart-full';

/**
 * Shipping Component
 */
const BookmarkButton = ({ i18n, bookmarked = false, onClick = null }) => {
  const addFavorite = i18n.gettext('Agregar a favoritos');
  const removeFavorite = i18n.gettext('Quitar de favoritos');
  const textFavorite = bookmarked ? removeFavorite : addFavorite;

  const Icon = !bookmarked ? heartEmptyIcon : heartFullIcon;
  const classNames = 'ui-item__bookmark';
  return (
    <button
      className={bookmarked ? `${classNames} ui-item__bookmark--added` : classNames}
      onClick={onClick}
    >
      <span className="clipped">{textFavorite}</span>
      <Icon className="ui-item__bookmark-icon" />
    </button>
  );
};

/**
 * PropTypes
 */
BookmarkButton.propTypes = {
  bookmarked: bool,
  onClick: func,
  i18n: object.isRequired,
};

/**
 * Expose BookmarkButton
 */
export default injectI18n(BookmarkButton);
