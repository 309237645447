/**
* Styles
*/
import '../../shared/components/pages/categories/categories.scss';

/**
* Module dependencies
*/
import startApp from '../start-app';

import Categories from '../../shared/components/pages/categories/categories';

startApp(Categories);
