import AdvertisementSquare from './advertisement-square';
import ActionsBox from './actions-box';
import DealItems from './deal-items';

const Components = {
  'advertisement-square': AdvertisementSquare,
  'actions-box': ActionsBox,
  'deal-items': DealItems,
};

export default Components;
