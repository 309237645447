import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { Card } from '@andes/card';
import Action from '../../../commons/action';

function ActionsBox({ type, main_actions, secondary_actions }) {
  return (
    <article className={type}>
      <Card>
        {main_actions.map(action => (
          <Action key={action.target} className="ui-button ui-button--primary" {...action} />
        ))}
        {secondary_actions.length > 0
          && (
            <div>
              <hr />
              {secondary_actions.map(action => (
                <Action key={action.target} className="secondary-action" {...action} />
              ))}
            </div>)}
      </Card>
    </article>
  );
}

ActionsBox.propTypes = {
  main_actions: arrayOf(shape(Action.propTypes)).isRequired,
  secondary_actions: arrayOf(shape(Action.propTypes)).isRequired,
  type: string.isRequired,
};

export default ActionsBox;
