import React from 'react';
import { shape } from 'prop-types';
import logger from 'nordic/logger';

import Section from '../section';
import TrackNewrelic from '../track-newrelic';
import FiscalData from '../../pages/home/fiscal-data';

const log = logger('app/components/commons/row-reegulation');

const RegulationRow = ({
  componentProps,
  childrenProps,
  availableComponents,
}) => {
  const { deviceType, siteId } = childrenProps;
  const { components, type } = componentProps;
  const componentsList = components;
  const namespace = 'container';
  return (
    <Section type={type}>
      <div className={deviceType !== 'mobile' ? namespace : null}>
        <div className={`${namespace}-${type}`}>
          {componentsList.map((component, i) => {
            const ReactComponent = availableComponents[component.type];
            if (!ReactComponent) {
              log.error(
                `Type '${component.type}' does not match any react component.`,
              );
              return (
                // eslint-disable-next-line max-len
                <TrackNewrelic
                  key={component.type}
                  newrelicNoticeError={{
                    message: `Type '${component.type}' does not match any react component.`,
                  }}
                />
              );
            }
            return (
              <ReactComponent
                key={component.id || component.type}
                {...component}
                {...childrenProps}
                elementOrder={i}
              />
            );
          })}
          <FiscalData siteId={siteId} deviceType={deviceType} />
        </div>
      </div>
    </Section>
  );
};

RegulationRow.propTypes = {
  componentProps: shape({}),
  childrenProps: shape({}),
  availableComponents: shape({}),
};

export default RegulationRow;
