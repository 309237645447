/**
 * Module dependencies
 */
import React from 'react';

import Action from './action';
import Image from './image-desktop';
import PriceBlock from './price-block';
import BookmarkButton from './bookmark-button';
import Review from './review';
import Props from './props';

// eslint-disable-next-line
const goToBrand = (url) => (window.location = url);

/**
 * Item Component
 */
/* istanbul ignore next */
const Item = (props) => {
  const {
    actions,
    attributes,
    bookmarked,
    brand,
    className = null,
    condition,
    discount,
    id,
    image,
    imgSize,
    title,
    titleRef,
    lazyLoad,
    meta,
    price,
    installments,
    free_installments,
    shipping,
    url,
    review,
    onFavClick,
  } = props;
  let classNames = 'ui-item';
  if (className) classNames += ` ${className}`;

  return (
    <div
      className={classNames}
      itemScope={meta ? true : undefined}
      itemType={meta ? 'http://schema.org/Product' : undefined}
    >
      {/**
       * Image: supports retina sizes
       */}
      <Image
        {...image}
        size={imgSize}
        title={title}
        lazyLoad={lazyLoad}
        url={url}
      />
      <div className="ui-item__content">
        {/**
         * Price block: Main price, discount (original price + discount tag), installments
         */}
        {(price || discount || installments) && (
          <PriceBlock
            price={price}
            discount={discount}
            installments={installments}
            free_installments={free_installments}
            shipping={shipping}
          />
        )}
        {/**
         * Attributes as text. For example: "2014 | 60000 km"
         */}
        {attributes && (
          <span className="ui-item__attributes">{attributes}</span>
        )}
        {/**
         * Title
         */}
        {title && (
          <p
            className="ui-item__title"
            itemProp={meta ? 'name' : undefined}
            ref={titleRef}
            aria-hidden="true"
          >
            {title}
          </p>
        )}
        {/**
         * Brand (Tienda oficial)
         */}
        {brand && (
          <button
            className="ui-item__brand"
            onClick={(e) => {
              e.preventDefault();
              goToBrand(brand.url);
            }}
          >
            {brand.text}
          </button>
        )}
        {/**
         * Review
         */}
        {review && (
          <Review
            className="ui-item__review"
            stars={review.stars}
            total={review.total}
          />
        )}

        {actions.map((action) => (
          <Action key={action.id} {...action} />
        ))}
      </div>
      {/**
       * BookmarkButton: add/added to favorites
       */}
      <BookmarkButton
        id={id}
        bookmarked={bookmarked}
        onClick={onFavClick}
      />
      {/**
       * Metadata: see schema.org
       */}
      {url && meta && (
        <meta itemProp="url" content={url} />
      )}
      {condition && meta && (
        <meta itemProp="itemCondition" content={condition} />
      )}
      {id && meta && (
        <meta itemProp="productID" content={id} />
      )}
    </div>
  );
};

/**
 * Prop types and defaults
 */
Item.propTypes = Props.propTypes;

/**
 * Expose Item
 */
export default Item;
