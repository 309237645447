/**
 * Module dependencies
 */
import React from 'react';
import { object, string, shape, bool } from 'prop-types';
import classNames from 'classnames';
import { MoneyAmount } from '@andes/money-amount';
import { injectI18n } from 'nordic/i18n';

import FullGlyph from '../icons/full';
/**
 * PriceBlock Component
 */

const PriceBlock = ({
  price,
  discount = null,
  installments = null,
  free_installments,
  shipping = null,
  i18n,
}) => {
  // Hotfix till homes-korriban send installments as object with a free_installments key
  const freeInstallments = installments?.includes('sin') || installments?.includes('sem') || free_installments;

  return (
    <div className="ui-item__price-block">
      {/**
       * Price and Discount Wrapper: Vertical align *center* for price and deal %
       */}
      <div className="ui-item__price-and-discount-wrapper">
        {price.text ? (
          <span className="ui-item__price">{price.text}</span>
        ) : null}
        {/**
         * Discount price: disabled if an original_price exists
         */}
        {discount ? (
          <MoneyAmount
            value={{
              fraction: discount.original_price,
            }}
            className="ui-item__discount-price"
            size={14}
            compact
            previous
          />
        ) : null}
        {/**
         * The main price of the item
         */}
        {price.currency_id ? (
          <MoneyAmount
            value={{
              fraction: price.fraction,
              cents: price.cents,
            }}
            symbol={price.symbol}
            currencyId={price.currency_id}
            className="ui-item__price"
            size={24}
            compact
          />
        ) : null}
        {/**
         * Discount percentage tag with the diff between original_price and price. For example: "25% OFF"
         */}
        {discount ? (
          <span className="ui-item__discount-text">{discount.text}</span>
        ) : null}
      </div>
      {/**
       * Installments as text. For example: "6x $5.000 sin interés"
       */}
      {installments ? (
        <span
          className={classNames('ui-item__installments', {
            'ui-item__installments--free': freeInstallments,
          })}
        >
          {installments}
        </span>
      ) : null}
      {/**
       * Free shipping
       */}
      <div className="ui-item__container-free">
        {shipping && shipping.freeShipping && (
          <p className="ui-item__shipping-free">
            {i18n.gettext('Envío gratis')}
          </p>
        )}
        {shipping && shipping.fullFilment && (
          <FullGlyph className="ui-item__full-icon" />
        )}
      </div>
    </div>
  );
};

PriceBlock.propTypes = {
  price: object.isRequired,
  discount: object,
  installments: string,
  free_installments: bool,
  shipping: shape({
    freeShipping: bool,
    text: string.isRequired,
    fullFilment: bool,
  }),
  i18n: object.isRequired,
};

/**
 * Expose PriceBlock
 */

export default injectI18n(PriceBlock);
