import React from 'react';
import { arrayOf, shape, string, object, bool } from 'prop-types';
import Item from '../../../commons/item';

function DealItems({ items, metadata, restClient, type, lazyLoad }) {
  return (
    <article className={type}>
      {items.map(item => (
        <Item lazyLoad={lazyLoad} key={item.id} metadata={metadata} restClient={restClient} {...item} />
      ))}
    </article>
  );
}

DealItems.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ).isRequired,
  metadata: Item.metadataPropTypes.isRequired,
  restClient: object.isRequired,
  type: string.isRequired,
  lazyLoad: bool.isRequired,
};

export default DealItems;
