import React from 'react';
import { object, string, oneOf, bool, shape } from 'prop-types';
import { Style } from 'nordic/style';
import { Script } from 'nordic/script';
import { MelidataTrack } from 'nordic/melidata/melidata-track';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import classNames from 'classnames';
import { AndesProvider } from '@andes/context';

import Header from '../../commons/header';
import Section from '../../commons/section';
import ComponentList from '../../commons/component-list';
import CategoryList from './category-list';
import CategoryListMobile from './category-list-mobile';
import components from './components';
import { SVG_SPRITES } from '../../../../utils/constants';

function Categories({
  atf,
  csrfToken,
  deviceType,
  currentUser = null,
  lowEnd,
  restClient,
  siteId,
  canonical,
  webView = false,
  country = null,
  nonce,
  serializeProps,
}) {
  const { metadata } = atf;
  const forwardedProps = { currentUser, lowEnd, restClient, metadata, canonical, nonce };
  const pageHeader = !webView ? atf.header : { ...atf.header, title: atf.title };

  return (
    <div className={classNames('categories', { 'low-end': lowEnd })}>
      <MelidataTrack
        path="/home/category"
        type="view"
        event_data={{
          category_id: atf.category_id,
          category_path: [atf.category_id],
        }}
      />
      {pageHeader && <Header {...pageHeader} canonical={canonical} siteId={siteId} />}
      <Style href="categories.css" />
      <Script>{`window.ML_PRELOADED_STATE = ${serializeProps};`}</Script>
      <Hydrator ssrOnly>{SVG_SPRITES}</Hydrator>
      {deviceType === 'mobile' && (
        <AndesProvider locale={country?.locale?.replace('_', '-')} csrfToken={csrfToken}>
          <div className="container_mobile">
            <h1 className="category_list__title">{atf.title}</h1>
            <CategoryListMobile categoryList={atf.category_list} />
            {atf.legal_requirements && (
              <div className="legals">
                {atf.legal_requirements.text}
                <a
                  href={atf.legal_requirements.link_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {atf.legal_requirements.link_text}
                </a>
              </div>
            )}
          </div>
        </AndesProvider>
      )}

      {deviceType === 'desktop'
        && (
          <AndesProvider locale={country?.locale?.replace('_', '-')} csrfToken={csrfToken}>
            <Section type={atf.background_image && 'has-background'}>
              {atf.background_image
                && (
                  <div className="background">
                    <div style={atf.background_image && { backgroundImage: `url(${atf.background_image})` }} />
                  </div>)}
              <div className="container">
                <div className="section-header">
                  <h1>{atf.title}</h1>
                </div>
                <div className="column">
                  <CategoryList categoryList={atf.category_list} legalRequirements={atf.legal_requirements} />
                </div>
                <div className="column">
                  <ComponentList availableComponents={components} list={atf.components} {...forwardedProps} />
                </div>
              </div>

            </Section>
          </AndesProvider>
        )}
    </div>
  );
}

Categories.propTypes = {
  atf: object.isRequired,
  csrfToken: string.isRequired,
  currentUser: object,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  lowEnd: bool.isRequired,
  restClient: object.isRequired,
  siteId: string.isRequired,
  translations: object.isRequired,
  canonical: string.isRequired,
  webView: bool,
  country: shape({
    locale: string,
  }),
  nonce: string,
  serializeProps: string,
};

export default Categories;

