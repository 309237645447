import React from 'react';
import Props from './props';

const ICON_ID = 'star_full_icon';
const IconStarFull = ({ className = null }) => (
  <svg className={className} viewBox="0 0 33 32">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStarFull.propTypes = Props.propTypes;

const Icon = React.memo(IconStarFull);
Icon.ICON_ID = ICON_ID;

export default Icon;

