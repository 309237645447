import React from 'react';
import { string, func } from 'prop-types';

// eslint-disable-next-line no-undef
const goToAction = (url) => { window.location = url; };

function Action({ label, onClick = null, target = null }) {
  return (
    <button
      className="ui-item__action"
      onClick={onClick || ((e) => {
        e.preventDefault();
        goToAction(target);
      })}
    >{label}
    </button>
  );
}

Action.propTypes = {
  label: string.isRequired,
  onClick: func,
  target: string,
};

export default Action;

