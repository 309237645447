import React from 'react';
import { string, shape, number, oneOf, arrayOf, bool } from 'prop-types';
import classNames from 'classnames';

class Advertisement extends React.Component {
  constructor(props) {
    super(props);

    const { google_ad, startExpanded = true } = props;

    this.slotId = `slot_${google_ad.position}`;
    this.state = {
      loaded: false,
      show: startExpanded,
    };
  }

  componentDidMount() {
    const { document, window } = globalThis;
    const { nonce } = this.props;

    if (!globalThis.gptadslots) {
      globalThis.gptadslots = [];
    }
    if (!globalThis.googletag) {
      globalThis.googletag = { cmd: [] };
    }
    if (!globalThis.gptScript) {
      const script = document.createElement('script');
      globalThis.gptScript = script;

      script.type = 'text/javascript';
      script.src = '//www.googletagservices.com/tag/js/gpt.js';
      script.nonce = nonce;
      script.async = true;

      document.querySelector('script').parentNode.appendChild(script);
    }
    const { googletag, gptadslots } = globalThis;
    const { google_ad: googleAd, deviceType, siteId, url, currentUser } = this.props;
    const isMobile = deviceType === 'mobile';
    const supportIntersectionObserver = 'IntersectionObserver' in window
    && 'IntersectionObserverEntry' in window
    && 'isIntersecting' in window.IntersectionObserverEntry.prototype;
    const lazyLoadEnable = ['MLB'].includes(siteId) && supportIntersectionObserver;
    const timing = (() => {
      if (window.PerformanceNavigationTiming !== undefined) {
        const entry = performance.getEntriesByType('navigation')[0];

        if (entry && entry.duration) {
          return entry.duration;
        }
      }
      return 8000;
    })();

    const getLazyAd = () => {
      const lazyAd = new window.IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const node = entry.target;
            observer.unobserve(node);
            googletag.cmd.push(() => {
              googletag.pubads().refresh(gptadslots, { changeCorrelator: false });
              gptadslots.splice(0, 1);
            });
          }
        });
      }, {
        rootMargin: !isMobile ? '0px 0px 150px 0px' : '0px',
      });

      return lazyAd;
    };

    const googleAdsize = isMobile ? 'fluid' : googleAd.size;
    const googlePlatform = isMobile ? 'webmobile' : 'desktop';

    googletag.cmd.push(
      () => {
        googletag.pubads().set('page_url', url);
        googletag.pubads().enableSingleRequest();

        if (currentUser) {
          googletag.pubads().setTargeting('custID', [currentUser.id.toString()]);
        }
      },
      () => {
        if (lazyLoadEnable) {
          const observerAd = getLazyAd();

          setTimeout(() => {
            observerAd.disconnect();
            if (gptadslots.length) {
              googletag.pubads().refresh(gptadslots, { changeCorrelator: false });
            }
          }, timing);
          googletag.pubads().disableInitialLoad();
          observerAd.observe(document.querySelector(`#${this.slotId}`));
        }
        googletag.enableServices();
      },
      () => {
        this.slot = googletag
          .defineSlot(googleAd.unit, googleAdsize, this.slotId)
          .setTargeting('platform', [googlePlatform])
          .setTargeting('Posiciones', [googleAd.position])
          .addService(googletag.pubads());
        gptadslots.push(this.slot);
        googletag.display(this.slotId);
        googletag.pubads().addEventListener('slotRenderEnded', (e) => {
          this.setState({
            loaded: true,
            show: !e.isEmpty,
          });
        });
      },
    );
  }

  componentWillUnmount() {
    const { googletag } = globalThis;
    googletag.cmd.push(
      () => googletag.destroySlots([this.slot]),
    );
  }

  render() {
    const { className: classNameOriginal, caption = null } = this.props;

    const { show, loaded } = this.state;

    const className = classNames('advertisement', classNameOriginal, {
      hidden: !show,
      loaded,
    });
    const frameClassName = classNames('frame-container', classNameOriginal);
    return (
      <div className={className}>
        <div className={frameClassName} id={this.slotId} />
        {caption && <div className="caption">{caption}</div>}
      </div>
    );
  }
}

Advertisement.propTypes = {
  caption: string,
  className: string.isRequired,
  currentUser: shape({
    id: number.isRequired,
  }),
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  siteId: string.isRequired,
  google_ad: shape({
    size: arrayOf(number).isRequired,
    unit: string.isRequired,
    position: string.isRequired,
  }).isRequired,
  startExpanded: bool,
  url: string.isRequired,
  nonce: string,
};

export default Advertisement;
