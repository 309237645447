import React from 'react';
import Props from './props';

const ICON_ID = 'star_half_icon';
const IconStarHalf = ({ className = null }) => (
  <svg className={className} viewBox="0 0 32 32">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStarHalf.propTypes = Props.propTypes;

const Icon = React.memo(IconStarHalf);
Icon.ICON_ID = ICON_ID;

export default Icon;
