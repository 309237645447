import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import Advertisement from '../../../commons/advertisement';

function AdvertisementSquare(props) {
  const { type, className = null, ...AdProps } = props;
  return (
    <article className={`${type} AD_area`}>
      <Advertisement className={classNames('square', className)} {...AdProps} deviceType="desktop" />
    </article>
  );
}

AdvertisementSquare.propTypes = {
  type: string.isRequired,
  className: string,
};

export default AdvertisementSquare;
