import { shape, bool, string, number, arrayOf } from 'prop-types';

const priceShape = shape({
  currency_id: string,
  symbol: string,
  fraction: string.isRequired,
  decimal_separator: string,
  cents: string,
});

const propTypes = {
  /**
   * Required properties
   */
  id: string.isRequired,
  image: shape({
    src: string.isRequired,
    src2x: string,
    size: number,
  }).isRequired,
  /**
   * Optional properties
   */
  title: string,
  url: string,
  condition: string,
  className: string,
  tracking: string,
  bookmarked: bool,
  installments: string,
  attributes: string,
  lazyLoad: bool.isRequired,

  /**
   * Shaped props
   */
  price: priceShape,
  shipping: shape({
    freeShipping: bool,
    text: string.isRequired,
  }),
  brand: shape({
    url: string.isRequired,
    text: string.isRequired,
  }),
  discount: shape({
    text: string.isRequired,
    original_price: priceShape.isRequired,
  }),
  review: shape({
    stars: number.isRequired,
    total: number,
  }),
  actions: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ),
};

const defaultProps = {
  actions: [],
  shipping: null,
  title: null,
  brand: null,
  review: null,
  url: null,
  condition: null,
  tracking: null,
  id: null,
  installments: null,
  attributes: null,
  discount: null,
  className: '',
  bookmarked: false,
  cuurency_id: null,
  symbol: null,
};

export default { propTypes, defaultProps };
