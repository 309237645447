import React from 'react';
import { bool, string } from 'prop-types';
import { Image as ImageNordic } from 'nordic/image';
import { LAZYLOAD_ON, LAZYLOAD_OFF } from '../../../../utils/constants';

const Image = ({ lazyLoad, src, size = null, src2x = null, title = null, meta = false, url }) => (
  <div className="ui-item__image-container">
    <a className="ui-item__link" href={url}>
      {meta && <meta itemProp="image" content={src} />}
      <ImageNordic
        src={src}
        className="ui-item__image"
        width={size}
        height={size}
        alt={title}
        srcSet={src2x ? `${src} 1x, ${src2x} 2x` : null}
        lazyload={lazyLoad ? LAZYLOAD_ON : LAZYLOAD_OFF}
      />
    </a>
  </div>
);

Image.propTypes = {
  lazyLoad: bool.isRequired,
  src: string.isRequired,
  size: string,
  src2x: string,
  title: string,
  meta: bool,
  url: string.isRequired,
};

export default Image;

