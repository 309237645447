import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

/* eslint-disable react/no-array-index-key */
import { List, ListItem } from '@andes/list';

const categoryListNamespace = 'category-list';

function CategoryListMobile({ categoryList }) {
  return (
    <List className={`${categoryListNamespace}`}>
      {categoryList.map((L2, i) => (
        <ListItem
          key={`${categoryList.title}-${i}`}
          className={`${categoryListNamespace}__item`}
        >
          <a className={`${categoryListNamespace}__link`} href={L2.target}>
            <span>{L2.title}</span>
            <span className={`${categoryListNamespace}__link__chevron`} />
          </a>
        </ListItem>
      ))}
    </List>
  );
}
const categoryPropTypes = {
  target: string.isRequired,
  title: string.isRequired,
};

CategoryListMobile.propTypes = {
  categoryList: arrayOf(
    shape({
      ...categoryPropTypes,
      children: arrayOf(shape(categoryPropTypes)).isRequired,
    }),
  ).isRequired,
};

export default CategoryListMobile;
