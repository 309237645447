import React from 'react';
import { shape, number, string, object, array, bool } from 'prop-types';
import classNames from 'classnames';
import Image from '../image';
import itemHelper from './helper';
import withTracker from '../with-tracker';

const metadataPropTypes = shape({
  decimal_places: number.isRequired,
  decimal_separator: string.isRequired,
  thousand_separator: string.isRequired,
});

function withItemBehavior(WrappedComponent) {
  class WithItemBehavior extends React.Component {
    constructor(props) {
      super(props);

      const {
        price,
        className: classNameOriginal,
        picture,
        is_trigger,
        actions,
        installments,
        attributes,
        metadata,
        squared,
        shipping,
        permalink,
        bookmarked,
      } = props;

      const { decimal_places, thousand_separator } = metadata;
      const priceDigits = Math.floor(price.value).toString().length;
      const discount = itemHelper.getDiscount(props, decimal_places, thousand_separator);
      const className = classNames(classNameOriginal, {
        [`price-digits-${priceDigits}`]: discount,
        'price-text': price.text,
        'trigger-item': is_trigger,
        'with-actions': actions.length > 0,
        'with-discount': discount,
        'with-installments': installments,
        'with-attributes': attributes,
      });

      this.bookmark = this.bookmark.bind(this);
      this.restorePreviousBookmark = this.restorePreviousBookmark.bind(this);
      this.onFinishFetching = this.onFinishFetching.bind(this);
      this.switchBookmark = this.switchBookmark.bind(this);
      this.itemHover = this.itemHover.bind(this);
      this.isFetching = false;
      this.mappedProps = {
        ...props,
        className,
        discount,
        showTitle: squared,
        image: picture,
        // props.attributes are handle by /ui-item/item.
        installments: installments || null,
        price: {
          ...price,
          symbol: price.currency_symbol,
          fraction: itemHelper.getFormattedFraction(price.value, thousand_separator),
          cents: price.value % 1 ? itemHelper.getDecimalPart(price.value, decimal_places) : null,
          decimal_separator: metadata.decimal_separator, // Not displayed, but needed for microdata
        },
        shipping: shipping ? itemHelper.getShipping(shipping) : null,
        url: permalink,
        onFavClick: this.bookmark,
      };

      this.state = {
        bookmarked,
        singleLineTitleClassName: '',
      };
    }

    onFinishFetching() {
      this.isFetching = false;
    }

    switchBookmark({ bookmarked }) {
      return {
        bookmarked: !bookmarked,
      };
    }

    bookmark(e) {
      e.preventDefault();
      e.stopPropagation();

      if (!this.isFetching) {
        this.isFetching = true;
        this.setState(this.switchBookmark, this.updateBookmark);
      }
    }

    updateBookmark() {
      const { id, restClient } = this.props;
      const { bookmarked } = this.state;

      const { _csrf: csrf } = restClient.config.params;
      const verb = bookmarked ? 'post' : 'delete';
      restClient[verb](`/bookmarks/${id}`, {
        loginParams: {
          loginType: 'FAVORITE',
          item_id: id,
          go: `${globalThis.location.href}gz/home/api/bookmarks/${id}?_csrf=${csrf}`,
        },
        params: { go: `${globalThis.location.href}` },
      })
        .then(this.onFinishFetching)
        // Restore the previous bookmark state if the endpoint failed.
        .catch(this.restorePreviousBookmark);
    }

    restorePreviousBookmark(error) {
      if (error.response && error.response.status === 401) {
        const redirectUrl = error.response.data.url;
        if (redirectUrl) {
          window.location.assign(redirectUrl);
        }
      } else {
        this.setState(this.switchBookmark, this.onFinishFetching);
      }
    }

    itemHover() {
      if (this.title) {
        const itemDescriptionHeight = this.title.clientHeight;
        this.isSingleLineDescription(itemDescriptionHeight);
      }
    }

    isSingleLineDescription(height) {
      if (height <= 18) {
        this.setState({
          singleLineTitleClassName: 'with-single-line-description',
        });
      }
    }

    render() {
      let { bookmarked } = this.mappedProps;

      const { className: classNameOriginal, ...rest } = this.mappedProps;
      const { singleLineTitleClassName, bookmarked: bookmarkedState } = this.state;

      bookmarked = bookmarkedState;

      const className = classNames(classNameOriginal, singleLineTitleClassName);
      const wrapperClassNames = className && className.split(/\s+/).map(name => `${name}__wrapper`);
      return (
        <div
          className={classNames('ui-item__wrapper', wrapperClassNames)}
          // eslint-disable-next-line react/no-unknown-property
          onHover={this.itemHover}
        >
          <WrappedComponent
            {...{ ...rest, bookmarked }}
            className={className}
            titleRef={(title) => { this.title = title; }}
          />
        </div>
      );
    }
  }

  WithItemBehavior.itemPropTypes = {
    actions: array,
    bookmarked: bool,
    className: string,
    id: string.isRequired,
    is_trigger: bool,
    permalink: string.isRequired,
    picture: shape(Image.propTypes).isRequired,
    price: shape({
      currency_id: string,
      currency_symbol: string,
      text: string,
      value: number,
    }).isRequired,
    shipping: shape({
      free_shipping: bool.isRequired,
      label: string.isRequired,
    }),
    squared: bool,
  };

  WithItemBehavior.defaultProps = {
    actions: [],
    bookmarked: false,
    className: '',
    currency_id: null,
    currency_symbol: null,
    price: null,
    price_text: null,
    is_trigger: false,
    shipping: null,
    squared: false,
  };

  WithItemBehavior.propTypes = {
    ...WithItemBehavior.itemPropTypes,
    metadata: metadataPropTypes.isRequired,
    restClient: object.isRequired,
  };

  const exportItem = withTracker(WithItemBehavior);
  exportItem.metadataPropTypes = metadataPropTypes;
  return exportItem;
}

export default withItemBehavior;
