import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Card } from '@andes/card';
import times from 'lodash/times';
import Action from '../../../commons/action';

const COLUMNS = 3;

function splitCategories(average, total) {
  let i = 0;
  let currentAverage = average;
  return (groups, currentCategory) => {
    const categoryLength = currentCategory.children.length + 1;
    const groupLength = groups[i].size;
    const closestToAverage = groupLength + (categoryLength / 2) <= currentAverage;

    if (!closestToAverage) {
      i += 1;
      currentAverage = (total - groups[i - 1].size) / (COLUMNS - i);
    }

    groups[i].elements.push(currentCategory);
    groups[i].size += categoryLength;
    return groups;
  };
}

function CategoryList({ categoryList, legalRequirements = null }) {
  const total = categoryList.reduce((count, category) => count + 1 + category.children.length, 0);
  const lines = categoryList.length - COLUMNS;
  const average = (total + lines) / COLUMNS;
  const initializedGroups = times(COLUMNS, () => ({ elements: [], size: 0 }));

  const groupedCategories = categoryList.reduce(splitCategories(average, total), initializedGroups);

  const renderL2 = (aCategoryList) => {
    const { title, target } = aCategoryList;
    return (
      <h2 className="category category--title category--l2">
        <Action label={title} target={target} />
      </h2>
    );
  };

  const renderSubCategories = aCategoryList => (
    <ul>
      {
        aCategoryList.children.map(aCategory => (
          <li className="category" key={aCategory.target}>
            <Action label={aCategory.title} target={aCategory.target} />
          </li>
        ))
      }
    </ul>
  );

  return (
    <Card className="card-categories">
      <div className="categories">
        {
          groupedCategories.map((aGroup, index) => (
            <div className="group" key={`${aGroup.size}_${index.toString()}`}>
              {
                aGroup.elements.map(aCategoryList => (
                  <div className="categories__wrapper" key={aCategoryList.title}>
                    {renderL2(aCategoryList)}
                    {renderSubCategories(aCategoryList)}
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
      {
        legalRequirements
        && (
          <div className="legals">
            {legalRequirements.text}
            <a
              href={legalRequirements.link_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {legalRequirements.link_text}
            </a>
          </div>)
      }
    </Card>
  );
}

const categoryPropTypes = {
  target: string.isRequired,
  title: string.isRequired,
};

CategoryList.propTypes = {
  categoryList: arrayOf(
    shape({
      ...categoryPropTypes,
      children: arrayOf(shape(categoryPropTypes)).isRequired,
    }),
  ).isRequired,
  legalRequirements: shape({
    text: string.isRequired,
    link_text: string.isRequired,
    link_url: string.isRequired,
  }),
};

export default CategoryList;
